const config = {
    siteTitle: 'steinofen.rocks',
    siteTitleShort: 'steinofen.rocks',
    siteTitleAlt: 'steinofen.rocks',
    siteLogo: '/logos/logo-1024.png',
    siteUrl: 'https://www.steinofen.rocks',
    repo: 'https://github.com/derbalint/steinofenrocks',
    pathPrefix: '',
    dateFromFormat: 'YYYY-MM-DD',
    dateFormat: 'MMMM Do, YYYY',
    siteDescription:
      'Dein Lego® Blog der rockt. News, Angebote, Reviews und mehr.',
    siteRss: '/rss.xml',
    googleAnalyticsID: 'UA-156010185-1',
    postDefaultCategoryID: 'News',
    newsletter: '',
    newsletterEmbed: '',
    userName: 'Ferdinand',
    userEmail: 'contact@anitime.ai',
    userTwitter: 'steinofenrocks',
    menuLinks: [
      {
        name: 'Me',
        link: '/me/',
      },
      {
        name: 'Posts',
        link: '/blog/',
      },
      {
        name: 'Contact',
        link: '/contact/',
      },
    ],
    themeColor: '#3F80FF', // Used for setting manifest and progress theme colors.
    backgroundColor: '#ffffff',
  }
  
  // Make sure pathPrefix is empty if not needed
  if (config.pathPrefix === '/') {
    config.pathPrefix = ''
  } else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
  }
  
  // Make sure siteUrl doesn't have an ending forward slash
  if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1)
  
  // Make sure siteRss has a starting forward slash
  if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`
  
  module.exports = config
  