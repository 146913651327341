import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import config from "../../data/SiteConfig"

export default function PageTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const postNode = markdownRemark
  const page = frontmatter

  return (
    <Layout>
        <Helmet>
            <title>{`${page.title} – ${config.siteTitle}`}</title>
        </Helmet>
        <SEO postPath={frontmatter.path} postNode={postNode} postSEO />
        <div className="container">
            <article>
                <header className="page-header">
                    <h1>{frontmatter.title}</h1>
                </header>
                    <div className="page" dangerouslySetInnerHTML={{ __html: html }}/>
            </article>
        </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "de")
        path
        title
      }
    }
  }
`